import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'plants',
  formId: 'UK%20general%20plants',
  title: 'Plants',
  subTitle: 'Sorry, I have plants this weekend.',
  icon: '/icons/plants.svg',
  image: '/assets/images/sunrise/plants_supermercat.png',
  theme: 'sunrise-plants',
  sections: [
    {
      sectionId: 'hero',
      label: 'Plants',
      title: 'Sorry, I have plants this weekend.',
      description:
        'Nothing beats an office filled with plants! Besides being beautiful they absorb harmful UV rays, reduce glare and eliminate fatigue of the eyes. Now THAT makes you wanna go to the office on the weekend!',
      image: '/assets/images/sunrise/plants_supermercat.png',
      icon: '/icons/plants.svg',
      buttonText: 'Request a proposal',
    },
    {
      sectionId: 'options',
      title: 'Big or small - green or colourful!',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/plants/service_1.svg',
          title: 'Indoor plants',
          description: '',
        },
        {
          icon: '/assets/images/services/plants/service_2.svg',
          title: 'Plant maintenance',
          description: '',
        },
        {
          icon: '/assets/images/services/plants/service_3.svg',
          title: 'Flowers',
          description: '',
        },
        {
          icon: '/assets/images/services/plants/service_4.svg',
          title: 'Dried flowers',
          description: '',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Go green in just a few steps',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/plants/journey_1.svg',
          title: 'Tell us what you like',
          description: 'Give us pointers on what you would like and we’ll put something together.',
        },
        {
          icon: '/assets/images/services/plants/journey_2.svg',
          title: 'Receive proposal',
          description: 'We’ll create a detailed proposal for all your flower needs.',
        },
        {
          icon: '/assets/images/services/plants/journey_3.svg',
          title: 'Accept!',
          description: 'That’s it! You’re now about to go green!',
        },
        {
          icon: '/assets/images/services/plants/journey_4.svg',
          title: 'Welcome to the new jungle',
          description: 'Ahhh the smell of flowers and the immediate happines at the office!',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Why companies love plants from Good Monday!',
      description: '',
      image: '/assets/images/services/plants/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-droplet-outline',
          title: 'DIY or full service',
          description: 'You can either water your plants yourself or have our partners do it. Up to you!',
        },
        {
          icon: 'eva eva-bulb-outline',
          title: 'Inspiration',
          description: 'We have a vast catalogue of amazing greens that fit all spaces.',
        },
        {
          icon: 'eva eva-phone-call-outline',
          title: 'Consultancy about your new plants',
          description:
            'Sunshine or no sunshine. No worries. We’ll guide you towards the best plants and flowers out there.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Did you know that Good Monday can make sure your plants never die and always grow big and strong?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Request a proposal',
    },
  ],
}
